@tailwind base;
@tailwind components;
@tailwind utilities;

input.input-autofill:-webkit-autofill,
input.input-autofill:-webkit-autofill:hover,
input.input-autofill:-webkit-autofill:focus,
input.input-autofill:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px trasparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

input.number-arrow::-webkit-outer-spin-button,
input.number-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}