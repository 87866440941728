body {
  font-family: "Roboto", sans-serif;
}

h1.heading-one {
  font-size: clamp(30px, 5vw, 60px);
  font-family: "Noto Serif", serif;
  line-height: 1.1;
}

.noto {
  font-family: "Noto Serif", serif;
}

.shadow {
  box-shadow: 0px 4px 7.8px 0px #0000001A;

}

.search-area img {
  max-width: 200px;
}

/* input:not([type='checkbox'], [type='radio']) {
  width: 100%;
  background: transparent;
  color: #1F1F28;
  font-size: 14px;
} */

input:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* width */
.formWrapper::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.formWrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.formWrapper::-webkit-scrollbar-thumb {
  background: #3b82f6;
  border-radius: 10px;
}

/* Handle on hover */
.formWrapper::-webkit-scrollbar-thumb:hover {
  background: #1563e2;
}



.custom-check label {
  text-align: left;
}

.excutive--form input {
  color: #525252;
}

.custom--edittable tr th,
.custom--edittable tr td {
  padding: 20px;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.popup-open {
  opacity: 1;
  pointer-events: auto;
  animation: slideUp 0.5s forwards;
  z-index: 9;
}

.popup-close {
  opacity: 0;
  pointer-events: none;
  animation: slideDown 0.3s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 85vh;
  border-radius: 0;
  border-top: 1px solid #dddddd4d;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}


@media only screen and (max-width:768px) {
  .formWrapper {
    padding-top: 0;
    padding-right: 3px;
  }

  .popup-content {
    height: 95vh;
    padding-top: 60px;
  }

  .popup-content .formWrapper {
    height: 100%;
  }
}